.logo {
  display: block;
  margin: 0 auto;
  margin-top: 5vh;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  max-width: 400px;
}

.subtitle {
  text-align: center;
  color: #FFF;
  /* text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3); */
  font-weight: 600;
  font-size: 2rem;
}

.mac {
  display: block;
  margin: 0 auto;
  max-width: 600px;
}

.header {
  margin-bottom: 20px;
}