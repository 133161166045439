.footer {
  /* background-image: linear-gradient(#FF8017, #FFA800); */
  background-repeat: no-repeat, no-repeat;
  background-color: #FF8017;
  /* padding: 30px; */
  padding-bottom: 100px;
}

.column {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 10px;
}

.row {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;
  align-items: flex-start;
}

.address {
  margin: 0;
  color: white;
  text-align: left;
  align-self: flex-start;
}

.company {
  font-size: larger;
}

.copyright {
  color: white;
}

.dots {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
}

.transition {
  width: 100%;
  overflow-x: hidden;
  z-index: 999;
  background-color: #FFF;
  position: relative;
  text-align: center;
  color: white;
}