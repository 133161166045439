.body {
  background: white;
  /* background-image: url(../../assets/white.png); */
  padding: 20px;
}

.row {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.transition {
  width: 100%;
  overflow-x: hidden;
  z-index: -999;
  margin-bottom: -10px;
  margin-top: -40%;
  position: relative;
  text-align: center;
  color: white;
}

@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
  }
}