.newsletter {
  display: block;
  margin: 0 auto;
  border: none;
  border-width: 0px;
  background: transparent;
}

.helper {
  color: #FFF;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  max-width: 300px;
  margin: 25px auto;
  /* text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3); */
}

.form {
  padding: 3px;
  text-align: center;
}

.textField {
  margin: 0;
  border-radius: 16px 0 0 16px;
  border: none;
  outline: none;
  text-align: left;
  padding: 0.75rem 1rem;
  line-height: 2rem;
  font-size: inherit;
  /* the following ensures they're all using the same box-model for rendering */
  -moz-box-sizing: content-box; /* or `border-box` */
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.button {
  margin: 0;
  border-radius: 0 16px 16px 0;
  background-color: #423E3F;
  border: none;
  outline: none;
  color: white;
  font-weight: 700;
  padding: 0.75rem 1rem;
  line-height: 2rem;
  font-size: inherit;

  /* The following ensures they're all using the same box-model for rendering */
  -moz-box-sizing: content-box; /* or `border-box` */
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.fields {
  border: none;
}

@media screen and (max-width: 400px) {
  .textField  {
    padding: 0.25rem 0.5rem;
  }
  .button  {
    padding: 0.25rem 0.5rem;
  }
}