@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Nunito', sans-serif;
}

body {
  background-image: linear-gradient(#FFA800, #FF8017);
  background-repeat: no-repeat, repeat;
  background-color: #FF8017;
  overflow-x: hidden;
}