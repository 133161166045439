.quote {
  color: #000;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.1rem;
}

.author {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.1rem;
}