@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Nunito', sans-serif;
}

body {
  background-image: linear-gradient(#FFA800, #FF8017);
  background-repeat: no-repeat, repeat;
  background-color: #FF8017;
  overflow-x: hidden;
}
.Newsletter_newsletter__1jjGB {
  display: block;
  margin: 0 auto;
  border: none;
  border-width: 0px;
  background: transparent;
}

.Newsletter_helper__2aQsv {
  color: #FFF;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  max-width: 300px;
  margin: 25px auto;
  /* text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3); */
}

.Newsletter_form__1UiYM {
  padding: 3px;
  text-align: center;
}

.Newsletter_textField__5EyQK {
  margin: 0;
  border-radius: 16px 0 0 16px;
  border: none;
  outline: none;
  text-align: left;
  padding: 0.75rem 1rem;
  line-height: 2rem;
  font-size: inherit;
  /* the following ensures they're all using the same box-model for rendering */ /* or `border-box` */
  box-sizing: content-box;
}

.Newsletter_button__3XKf7 {
  margin: 0;
  border-radius: 0 16px 16px 0;
  background-color: #423E3F;
  border: none;
  outline: none;
  color: white;
  font-weight: 700;
  padding: 0.75rem 1rem;
  line-height: 2rem;
  font-size: inherit;

  /* The following ensures they're all using the same box-model for rendering */ /* or `border-box` */
  box-sizing: content-box;
}

.Newsletter_fields__8p_lO {
  border: none;
}

@media screen and (max-width: 400px) {
  .Newsletter_textField__5EyQK  {
    padding: 0.25rem 0.5rem;
  }
  .Newsletter_button__3XKf7  {
    padding: 0.25rem 0.5rem;
  }
}
.Header_logo__3_SJs {
  display: block;
  margin: 0 auto;
  margin-top: 5vh;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  max-width: 400px;
}

.Header_subtitle__VxvKU {
  text-align: center;
  color: #FFF;
  /* text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3); */
  font-weight: 600;
  font-size: 2rem;
}

.Header_mac__2K-x3 {
  display: block;
  margin: 0 auto;
  max-width: 600px;
}

.Header_header__1VCKf {
  margin-bottom: 20px;
}
.Body_body__3gqj9 {
  background: white;
  /* background-image: url(../../assets/white.png); */
  padding: 20px;
}

.Body_row__2xGiR {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.Body_transition__3NDLK {
  width: 100%;
  overflow-x: hidden;
  z-index: -999;
  margin-bottom: -10px;
  margin-top: -40%;
  position: relative;
  text-align: center;
  color: white;
}

@media screen and (max-width: 800px) {
  .Body_row__2xGiR {
    flex-direction: column;
  }
}
.Column_column__2T0xE {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.Column_title__1vO-N {
  font-weight: 600;
  font-size: 24px;
}

@media screen and (max-width: 800px) {
  .Column_column__2T0xE {
    flex: 1 1;
  }
}
.Quote_quote__6yKJj {
  color: #000;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.1rem;
}

.Quote_author__2em6V {
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.1rem;
}
.Footer_footer__1w0lV {
  /* background-image: linear-gradient(#FF8017, #FFA800); */
  background-repeat: no-repeat, no-repeat;
  background-color: #FF8017;
  /* padding: 30px; */
  padding-bottom: 100px;
}

.Footer_column__2v8Lo {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin: 10px;
}

.Footer_row__1mE-5 {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;
  align-items: flex-start;
}

.Footer_address__Dr0yC {
  margin: 0;
  color: white;
  text-align: left;
  align-self: flex-start;
}

.Footer_company__htHzj {
  font-size: larger;
}

.Footer_copyright__2OWH4 {
  color: white;
}

.Footer_dots__3O637 {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
}

.Footer_transition__2rn67 {
  width: 100%;
  overflow-x: hidden;
  z-index: 999;
  background-color: #FFF;
  position: relative;
  text-align: center;
  color: white;
}
